import React, { useContext } from 'react';
import Layout from '../layouts/Layout';
import SEO from '../components/seo';
import Header from '../components/Sites/Home/Header/Header';
import Make from '../components/Sites/Home/Make/Make';
import Clients from '../components/Sites/Home/Clients/Clients';
import styled from 'styled-components';
// import { MainPageContext } from '../context/MainPageContextProvider';
import Intro from '../components/Sites/Home/Intro/Intro';
import {VideoModal} from "../components/videoModal";

// const StyledLayout = styled(Layout)`
//   visibility: ${({ showIntro }) => (showIntro ? 'hidden' : 'visible')};
// `;

const IndexPage = ({
  location: { pathname: path, search },
  transitionStatus,
  entry,
  exit,
}) => {
    const [open , setOpen] = React.useState(false);
  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      const params = new URLSearchParams(search);
      if (params.get('target') === '15lat') {
        setTimeout(() => {
              setOpen(true);
        }, 1000)
      }
    }
  }, [search]);

    // const { showIntro } = useContext(MainPageContext);
  return (
    <>
      {/*<Intro />*/}
        {(typeof window !== "undefined" && typeof document !== "undefined") && <VideoModal isOpen={open} onClose={() => setOpen(false)}/>}
        <Layout
        // showIntro={showIntro}
        className="layout"
        isLinkToTop={path === '/'}
        id="mainPage"
        path={path}
        transitionStatus={transitionStatus}
        entry={entry}
        exit={exit}
      >
        <SEO title="Makadu" />
        <Header setOpen={() => setOpen(true)} />
        <Make />
        <Clients />
      </Layout>
    </>
  );
};

export default IndexPage;
